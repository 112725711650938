<template>
  <div style="height: 730px">
    <iframe
      v-if="iframeSrc"
      ref="frame"
      :src="iframeSrc"
      width="100%"
      height="100%"
    />
  </div>
</template>
<script>
import { VBModal, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  components: {
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
  },
  data() {
    return {
      qrcode_id: router.currentRoute.query.id,
      qrcode_type: router.currentRoute.query.qrcode_type,
      iframeSrc: null,
    }
  },
  watch: {},
  created() {
    let qrcode_designer = null
    if (process.env.NODE_ENV == 'development') {
      qrcode_designer = 'http://localhost:3000/'
    } else {
      qrcode_designer = 'https://qrcodedesigner.geribildir.io/'
    }
    const accessToken = localStorage.getItem('accessToken')
    this.iframeSrc = `${qrcode_designer}?qrcode_id=${this.qrcode_id}&qrcode_type=${this.qrcode_type}&accessToken=${accessToken}`
  },
  mounted() {},
  methods: {},
}
</script>
<style>

</style>
